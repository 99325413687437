import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { api } from '~/config/api';
import { CacheKeys } from '~/config/cache';

import { Storage } from '../utils/storage';

const PATHS_WITHOUT_TOKEN = [api.auth.login];

export class HttpService {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: api.baseUrl,
    });

    this.instance.interceptors.request.use((config) => {
      const token = Storage.get(CacheKeys.authToken);
      const isPathWithoutToken = PATHS_WITHOUT_TOKEN.some((path) => config.url?.endsWith(path));

      if (!!token?.length && !isPathWithoutToken) {
        config.headers.set('Authorization', `Bearer ${token}`);
      }

      return config;
    });
  }

  get<R>(url: string, config?: AxiosRequestConfig) {
    return this.instance.get<R>(url, config);
  }

  post<R>(url: string, data: any, config?: AxiosRequestConfig) {
    return this.instance.post<R>(url, data, config);
  }

  patch<R>(url: string, data: any, config?: AxiosRequestConfig) {
    return this.instance.patch<R>(url, data, config);
  }

  put<R>(url: string, data: any, config?: AxiosRequestConfig) {
    return this.instance.put<R>(url, data, config);
  }

  delete<R>(url: string, config?: AxiosRequestConfig) {
    return this.instance.delete<R>(url, config);
  }
}
