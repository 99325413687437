import { api } from '~/config/api';

import { HttpService } from './http';
import { GetMeResult, LoginPayload, LoginResult } from './types/auth';

export class AuthService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  login(payload: LoginPayload) {
    return this.httpService.post<LoginResult>(api.auth.login, payload);
  }

  getMe() {
    return this.httpService.get<GetMeResult>(api.auth.me);
  }
}
