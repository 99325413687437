export class Storage {
  static get(key: string) {
    const value = localStorage.getItem(key);

    if (value) {
      try {
        return JSON.parse(value);
      } catch {
        return null;
      }
    }

    return null;
  }

  static set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static remove(key: string) {
    localStorage.removeItem(key);
  }
}
