import { Route, RouteProps, Routes } from 'react-router-dom';

import { appRoutes } from '~/config/routes';
import { LoginPage } from '~/pages/login';
import { NotFoundPage } from '~/pages/not-found';
import { TokensPage } from '~/pages/tokens';
import { UsersPage } from '~/pages/users';

const routes: RouteProps[] = [
  {
    path: appRoutes.base,
    element: <TokensPage />,
  },
  {
    path: appRoutes.users,
    element: <UsersPage />,
  },
  {
    path: appRoutes.login,
    element: <LoginPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export function Router() {
  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.path} {...route} />
      ))}
    </Routes>
  );
}
