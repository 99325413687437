import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/button';
import { EditIcon } from '~/components/icons/edit';
import { PlusIcon } from '~/components/icons/plus';
import { PageLayout } from '~/components/layout';
import { LoadingSpinner } from '~/components/loading';
import { useTokens } from '~/hooks/use-tokens';

import { CreateTokenModal } from './components/create-token-modal';
import { ShowTokenModal } from './components/show-token-modal';

export function TokensPage() {
  const { t } = useTranslation();
  const { data: tokens, isLoading } = useTokens();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isShowModalOpen, setIsShowModalOpen] = useState<boolean>(false);
  const [selectedTokenId, setSelectedTokenId] = useState<string | null>(null);

  const handleOpenCreateModal = () => setIsCreateModalOpen(true);
  const handleCloseCreateModal = () => setIsCreateModalOpen(false);

  const handleShowToken = (token: string) => {
    setSelectedTokenId(token);
    setIsShowModalOpen(true);
  };

  const handleCloseShowModal = () => {
    setSelectedTokenId(null);
    setIsShowModalOpen(false);
  };

  return (
    <>
      <CreateTokenModal visible={isCreateModalOpen} onClose={handleCloseCreateModal} />

      <ShowTokenModal
        tokenId={selectedTokenId}
        visible={isShowModalOpen}
        onClose={handleCloseShowModal}
      />

      <PageLayout>
        <h1 className="text-xl font-bold">{t('PAGES.MENU.TOKENS')}</h1>

        <div className="w-full mt-4">
          <Button onClick={handleOpenCreateModal} type="button" contentCenter>
            <PlusIcon className="p-0.5" />
            {t('PAGES.TOKENS.CREATE')}
          </Button>

          {isLoading && (
            <div className="w-full mt-4 flex justify-start">
              <LoadingSpinner />
            </div>
          )}

          {!isLoading && (
            <div className="flex flex-col gap-2 mt-4 w-full max-w-full h-full">
              {(tokens || []).map((token) => (
                <div
                  key={token.id}
                  className="flex justify-between items-center gap-8 border-solid border-2 border-slate-200 rounded-lg shadow-sm flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4"
                >
                  <p className="cursor-pointer" onClick={() => handleShowToken(token.id)}>
                    {token.name}{' '}
                    {'is_active' in token && !token.is_active && `(${t('TITLES.INACTIVE')})`}
                  </p>

                  <div className="flex gap-2 items-center">
                    <button
                      onClick={() => handleShowToken(token.id)}
                      type="button"
                      className="appearence-none cursor-pointer"
                    >
                      <EditIcon />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </PageLayout>
    </>
  );
}
