import { useEffect, useState } from 'react';

import { Storage } from '../utils/storage';

export function useLocalStorageState<S = unknown>(key: string, defaultValue: S) {
  const [state, setState] = useState<S>(() => {
    const valueFromStorage = Storage.get(key);
    if (!valueFromStorage) return defaultValue;
    return valueFromStorage;
  });

  useEffect(() => {
    Storage.set(key, state);
  }, [state, key]);

  return [state, setState] as [S, React.Dispatch<React.SetStateAction<S>>];
}
