import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';

import { ReactPortal } from '~/components/react-portal';

import { CloseIcon } from '../icons/close';

type Props = {
  visible: boolean;
  containerClassName?: string;
  onClose: () => void;
  disabledClose?: boolean;
  children: React.ReactNode;
};

export function Modal(props: Props) {
  const { visible, disabledClose, onClose, containerClassName, children } = props;

  return (
    <ReactPortal containerId="modal-root">
      <Transition.Root show={visible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className={clsx('bg-white px-4 py-4', containerClassName)}>
                    <div className="flex justify-end my-0.5">
                      <button
                        type="button"
                        className="appearence-none"
                        disabled={disabledClose}
                        onClick={onClose}
                      >
                        <CloseIcon />
                      </button>
                    </div>

                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </ReactPortal>
  );
}
