import ReactDOM from 'react-dom';

type Props = {
  containerId?: string;
  children: React.ReactNode;
};

export function ReactPortal(props: Props) {
  const { containerId = 'portal-root', children } = props;

  let container = document.getElementById(containerId);

  if (!container) {
    container = document.createElement('div');
    container.setAttribute('id', containerId);
    document.body.appendChild(container);
  }

  return ReactDOM.createPortal(children, container);
}
