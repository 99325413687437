import { api } from '~/config/api';

import { HttpService } from './http';
import { CreateUserPayload, CreateUserResult, ListUsersResult } from './types/user';

export class UserService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  list() {
    return this.httpService.get<ListUsersResult>(api.users.list);
  }

  create(payload: CreateUserPayload) {
    return this.httpService.post<CreateUserResult>(api.users.base, payload);
  }

  delete(id: string) {
    return this.httpService.delete(api.users.delete.replace(':id', id));
  }
}
