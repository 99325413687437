import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

import { LoadingSpinner } from '~/components/loading';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'outlined';
  contentCenter?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
};

export function Button(props: Props) {
  const { contentCenter, loading, fullWidth, variant = 'primary', ...buttonProps } = props;

  return (
    <button
      {...buttonProps}
      className={clsx(
        'transition ease-in-out delay-150',
        'rounded-lg',
        'shadow-sm',
        'text-center text-sm font-medium',
        'focus:ring-4 focus:outline-none',
        'disabled:opacity-50',
        'px-4 py-2',
        variant === 'primary' &&
          'bg-primary-600 enabled:hover:bg-primary-700 focus:ring-primary-300 text-white',
        variant === 'outlined' &&
          'text-gray-900 ring-1 ring-inset ring-gray-300 enabled:hover:bg-gray-50',
        fullWidth && 'w-full',
        (!!contentCenter || !!loading) && 'flex justify-center items-center gap-0.5',
        props.className || ''
      )}
    >
      {loading && <LoadingSpinner className="h-full" height="20px" width="20px" />}

      {!loading && props.children}
    </button>
  );
}
