import clsx from 'clsx';
import { SelectHTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronIcon } from '../icons/chevron';

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string;
  error?: string;
  options: {
    value: string;
    label: string;
  }[];
};

export function Select(props: Props) {
  const { id = 'input', label, error, options, ...selectProps } = props;
  const { t } = useTranslation();
  const [isOnFocus, setIsOnFocus] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <label className="text-gray-700 text-sm font-bold" htmlFor={id}>
          {label}
          {selectProps.required && <span className="text-red-500">&nbsp;{'*'}</span>}
        </label>
      )}

      <div className="relative w-full h-full">
        <select
          {...selectProps}
          onBlur={() => setIsOnFocus(false)}
          onFocus={() => setIsOnFocus(true)}
          className={clsx(
            'transition ease-in-out delay-150',
            'appearance-none',
            'border rounded',
            'py-2 px-3',
            'text-gray-700',
            'w-full',
            'shadow  leading-tight',
            'focus:outline-none focus:shadow-outline focus:border-primary-400',
            'cursor-pointer',
            !!error && 'border border-red-500',
            props.className || ''
          )}
        >
          <option value="" disabled>
            {t('TITLES.SELECT_AN_OPTION')}
          </option>

          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <div className="absolute pointer-events-none inset-y-0 right-0 flex items-center px-4 text-gray-600">
          <ChevronIcon className="w-4 h-1/3" position={isOnFocus ? 'up' : 'down'} />
        </div>
      </div>

      {!!error && <p className="text-red-500 text-xs bold">{error}</p>}
    </div>
  );
}
