import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Button } from '~/components/button';
import { Input } from '~/components/input';
import { Modal } from '~/components/modal';
import { useCreateUser } from '~/hooks/use-create-user';
import { RandomGenerator } from '~/utils/random';

type FormState = {
  email: string;
  password: string;
};

type Props = {
  visible: boolean;
  onClose: () => void;
};

export function CreateUserModal(props: Props) {
  const { visible, onClose } = props;
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useCreateUser();

  const {
    handleSubmit: handleFormSubmit,
    control,
    formState,
    setValue,
    trigger,
    reset,
  } = useForm<FormState>({
    resolver: yupResolver(createValidation()),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'all',
  });

  const handleFillRandomPassword = () => {
    setValue('password', RandomGenerator.generatePassword());
    trigger();
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleSubmit = async (formValues: FormState) => {
    try {
      const result = await mutateAsync({
        email: formValues.email,
        password: formValues.password,
      });

      if (result.success) {
        handleClose();
      }
    } catch {}
  };

  return (
    <Modal visible={visible} onClose={isPending ? () => {} : handleClose}>
      <form noValidate onSubmit={handleFormSubmit(handleSubmit)}>
        <div className="flex flex-col gap-8 px-4 py-2">
          <div className="flex flex-col gap-4">
            <h2 className="text-lg font-bold">{t('PAGES.USERS.CREATE')}</h2>

            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  type="email"
                  label={t('TITLES.EMAIL')}
                  name={field.name}
                  id={field.name}
                  value={field.value}
                  error={formState.errors?.[field.name]?.message}
                  onChange={(e) => field.onChange(e.target.value)}
                  required
                />
              )}
            />

            <div className="flex flex-col gap-2">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    label={t('TITLES.PASSWORD')}
                    name={field.name}
                    id={field.name}
                    value={field.value}
                    error={formState.errors?.[field.name]?.message}
                    onChange={(e) => field.onChange(e.target.value)}
                    required
                  />
                )}
              />

              <p
                className="transition ease-in-out delay-50 cursor-pointer hover:text-primary-500 select-none"
                onClick={handleFillRandomPassword}
              >
                {'Gerar senha aleatória'}
              </p>
            </div>
          </div>

          <div className="flex justify-end gap-4 w-1/2 self-end">
            <Button
              type="button"
              variant="outlined"
              fullWidth
              className="font-semibold"
              disabled={isPending}
              onClick={handleClose}
            >
              {t('TITLES.CANCEL')}
            </Button>

            <Button
              type="submit"
              fullWidth
              loading={isPending}
              disabled={!formState.isValid || isPending}
            >
              {t('TITLES.SAVE')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

const createValidation = () =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(t('ERRORS.INVALID_EMAIL'))
      .required(t('ERRORS.REQUIRED_FIELD')),
    password: yup.string().trim().required(t('ERRORS.REQUIRED_FIELD')),
  });
