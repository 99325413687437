const fetchRemoteConfig = () => {
  const request = new XMLHttpRequest();
  request.open('GET', '/conf.json', false);
  request.send(null);
  return JSON.parse(request.responseText);
};

const remoteConfig = fetchRemoteConfig();

const envs = {
  apiUrl: remoteConfig.url,
  appEnv: remoteConfig.env,
};

export class Env {
  static get(key: keyof typeof envs) {
    return envs[key];
  }
}
