import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { appRoutes } from '~/config/routes';
import { AuthService } from '~/services/auth';
import { LoginPayload } from '~/services/types/auth';

import { useAppContext } from './use-app-context';
import { useToast } from './use-toast';

const handleMutate = (payload: LoginPayload) => new AuthService().login(payload);

export function useLogin() {
  const { notify } = useToast();
  const { t } = useTranslation();
  const { updateToken, resetToken } = useAppContext();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: handleMutate,
    onSuccess: ({ data }) => {
      if (!!data?.success && !!data?.data?.token) {
        notify('success', t('TITLES.LOGIN_SUCCESS'));
        updateToken(data.data.token);
        navigate(appRoutes.base);
        return;
      }

      notify('error', t('ERRORS.WAS_NOT_POSSIBLE_LOGIN'));
      resetToken();
    },
    onError: () => {
      notify('error', t('ERRORS.WAS_NOT_POSSIBLE_LOGIN'));
      resetToken();
    },
  });
}
