import { useQuery } from '@tanstack/react-query';

import { TokenService } from '../services/token';

const tokensFetcher = () => new TokenService().list().then((result) => result.data?.data);

export function useTokens() {
  return useQuery({
    queryKey: ['tokens'],
    queryFn: tokensFetcher,
  });
}
