import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Button } from '~/components/button';
import { EyeIcon } from '~/components/icons/eye';
import { EyeSlashIcon } from '~/components/icons/eye-slash';
import { Input } from '~/components/input';
import { useAppContext } from '~/hooks/use-app-context';
import { useLogin } from '~/hooks/use-login';

type FormState = {
  email: string;
  password: string;
};

export function LoginPage() {
  const { t } = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { mutate, isPending } = useLogin();
  const { resetToken } = useAppContext();

  const {
    handleSubmit: handleFormSubmit,
    formState,
    control,
  } = useForm<FormState>({
    resolver: yupResolver(createValidation()),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'all',
  });

  const handleSubmit = (data: FormState) => {
    mutate(data);
  };

  const handleTogglePassword = () => setIsPasswordVisible((prevState) => !prevState);

  useEffect(() => {
    resetToken?.();
  }, []);

  return (
    <main className="bg-gray-50">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              {t('PAGES.LOGIN.TITLE')}
            </h1>

            <form
              noValidate
              className="space-y-4 md:space-y-6"
              onSubmit={handleFormSubmit(handleSubmit)}
            >
              <div>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="email"
                      label={t('TITLES.EMAIL')}
                      name={field.name}
                      id={field.name}
                      value={field.value}
                      error={formState.errors?.[field.name]?.message}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder={t('TITLES.EMAIL_PLACEHOLDER')}
                      required
                    />
                  )}
                />
              </div>

              <div>
                <div className="relative w-100 container">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type={isPasswordVisible ? 'text' : 'password'}
                        name={field.name}
                        label={t('TITLES.PASSWORD')}
                        id={field.name}
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        icon={isPasswordVisible ? <EyeSlashIcon /> : <EyeIcon />}
                        error={formState.errors?.[field.name]?.message}
                        onIconClick={handleTogglePassword}
                        placeholder={t('TITLES.PASSWORD_PLACEHOLDER')}
                        required
                      />
                    )}
                  />
                </div>
              </div>

              <Button
                fullWidth
                loading={isPending}
                disabled={!formState.isValid || isPending}
                type="submit"
              >
                {t('PAGES.LOGIN.SIGNIN')}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

const createValidation = () =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(t('ERRORS.INVALID_EMAIL'))
      .required(t('ERRORS.REQUIRED_FIELD')),
    password: yup.string().trim().required(t('ERRORS.REQUIRED_FIELD')),
  });
