import { TOKEN_PROVIDERS } from '~/config/constants/providers';

type PayloadInput = {
  name: string;
  provider: string;
  credentials: Record<string, string>;
};

export class TokenPayloadFormatter {
  static format(input: PayloadInput) {
    return {
      name: input.name,
      provider: input.provider,
      is_active: true,
      integration: {
        credentials: this.formatCredentials(input.provider, input.credentials),
      },
    };
  }

  private static formatCredentials(provider: string, credentials: Record<string, string>) {
    if (!credentials) return {};

    if (provider === TOKEN_PROVIDERS.SAMSUNG) {
      return { type: 'basic', ...credentials };
    }

    return credentials;
  }
}
