import { useQuery } from '@tanstack/react-query';

import { TokenService } from '~/services/token';

const tokenFetcher = (tokenId: string) =>
  new TokenService().getDetails(tokenId).then((result) => result.data?.data);

export function useToken(tokenId?: string | null) {
  return useQuery({
    queryKey: ['tokens', tokenId],
    queryFn: () => tokenFetcher(tokenId || ''),
    enabled: !!tokenId,
  });
}
