import { Env } from './env';

export const api = {
  baseUrl: Env.get('apiUrl') as string,
  auth: {
    login: '/users/login',
    me: '/users/me',
  },
  tokens: {
    base: '/tokens',
    list: '/tokens',
    details: '/tokens/:id',
    update: '/tokens/:id',
    delete: '/tokens/:id',
  },
  users: {
    base: '/users',
    list: '/users',
    delete: '/users/:id',
  },
};
