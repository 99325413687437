import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { UserService } from '~/services/user';

import { useToast } from './use-toast';

const handleMutate = (userId: string) => new UserService().delete(userId);

type Options = {
  onSuccess?: () => void;
};

export function useDeleteUser(options?: Options) {
  const { notify } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: handleMutate,
    onSuccess: () => {
      notify('success', t('MESSAGES.USER_DELETED_SUCCESS'));
      queryClient.invalidateQueries({ queryKey: ['users'] });
      options?.onSuccess?.();
    },
    onError: () => {
      notify('error', t('ERRORS.WAS_NOT_POSSIBLE_DELETE_USER'));
    },
  });
}
