import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { TokenService } from '../services/token';
import { CreateTokenPayload } from '../services/types/token';
import { useToast } from './use-toast';

const handleMutate = (data: CreateTokenPayload) =>
  new TokenService().create(data).then((result) => result.data);

export function useCreateToken() {
  const { notify } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: handleMutate,
    onSuccess: () => {
      notify('success', t('MESSAGES.TOKEN_CREATED_SUCCESS'));
      queryClient.invalidateQueries({ queryKey: ['tokens'] });
    },
    onError: () => {
      notify('error', t('ERRORS.WAS_NOT_POSSIBLE_CREATE_TOKEN'));
    },
  });
}
