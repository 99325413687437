import clsx from 'clsx';

type Props = {
  onChange: (checked: boolean) => void;
  checked: boolean;
  label?: string;
  value?: string;
};

export function SwitchToggle(props: Props) {
  const { value, onChange, checked, label } = props;

  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        value={value}
        className="sr-only peer"
        onChange={(e) => onChange(e.target.checked)}
        checked={checked}
      />
      <div
        className={clsx(
          'relative w-11 h-6 bg-gray-200 rounded-full',
          'peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full ',
          'peer-checked:bg-blue-600',
          'peer-checked:after:border-white after:content-[""] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full',
          'after:h-5 after:w-5 after:transition-all',
          'rtl:peer-checked:after:-translate-x-full'
        )}
      />
      {label && <span className="ms-3 text-sm font-medium text-gray-900">{label}</span>}
    </label>
  );
}
