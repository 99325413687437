import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useToast } from '~/hooks/use-toast';
import { CreateUserPayload } from '~/services/types/user';
import { UserService } from '~/services/user';

const handleMutate = (data: CreateUserPayload) =>
  new UserService().create(data).then((result) => result.data);

export function useCreateUser() {
  const { notify } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: handleMutate,
    onSuccess: () => {
      notify('success', t('MESSAGES.USER_CREATED_SUCCESS'));
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
    onError: () => {
      notify('error', t('ERRORS.WAS_NOT_POSSIBLE_CREATE_USER'));
    },
  });
}
