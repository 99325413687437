import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/button';
import { DeleteIcon } from '~/components/icons/delete';
import { PlusIcon } from '~/components/icons/plus';
import { LoadingSpinner } from '~/components/loading';
import { Modal } from '~/components/modal';
import { useDeleteUser } from '~/hooks/use-delete-user';
import { useUsers } from '~/hooks/use-users';

import { PageLayout } from '../../components/layout';
import { CreateUserModal } from './components/create-user-modal';

export function UsersPage() {
  const { t } = useTranslation();
  const { data: users, isLoading } = useUsers();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);

  const { mutate: deleteUser, isPending: isDeleting } = useDeleteUser({
    onSuccess: () => {
      setUserToDelete(null);
    },
  });

  const handleOpenCreateModal = () => setIsCreateModalOpen(true);
  const handleCloseCreateModal = () => setIsCreateModalOpen(false);

  const handleConfirmDelete = () => {
    if (!userToDelete) return;
    deleteUser(userToDelete);
  };

  const handleDelete = (id: string) => {
    setUserToDelete(id);
  };

  return (
    <>
      <CreateUserModal visible={isCreateModalOpen} onClose={handleCloseCreateModal} />

      <Modal visible={!!userToDelete} onClose={() => setUserToDelete(null)}>
        <div className="flex flex-col gap-8 px-4 pt-2">
          <h2 className="text-lg font-bold">{t('PAGES.USERS.DELETE_CONFIRM')}</h2>

          <div className="flex justify-end gap-4 w-1/2 self-end">
            <Button
              type="button"
              variant="outlined"
              fullWidth
              className="font-semibold"
              onClick={() => setUserToDelete(null)}
            >
              {t('TITLES.CANCEL')}
            </Button>

            <Button type="button" onClick={handleConfirmDelete} fullWidth loading={isDeleting}>
              {t('TITLES.DELETE')}
            </Button>
          </div>
        </div>
      </Modal>

      <PageLayout>
        <h1 className="text-xl font-bold">{t('PAGES.MENU.USERS')}</h1>

        <div className="w-full mt-4">
          <Button onClick={handleOpenCreateModal} type="button" contentCenter>
            <PlusIcon className="p-0.5" />
            {t('PAGES.USERS.CREATE')}
          </Button>

          {isLoading && (
            <div className="w-full mt-4 flex justify-start">
              <LoadingSpinner />
            </div>
          )}

          {!isLoading && (
            <div className="flex flex-col gap-2 mt-4 w-full max-w-full h-full">
              {(users || []).map((user) => (
                <div
                  key={user.id}
                  className="flex justify-between items-center gap-8 border-solid border-2 border-slate-200 rounded-lg shadow-sm flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4"
                >
                  <p>{user.email}</p>

                  <div className="flex gap-2 items-center">
                    <button
                      onClick={() => handleDelete(user.id)}
                      type="button"
                      className="appearence-none cursor-pointer"
                    >
                      <DeleteIcon className="text-red-500" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </PageLayout>
    </>
  );
}
