import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Button } from '~/components/button';
import { FieldSelector } from '~/components/field-selector';
import { Input } from '~/components/input';
import { Modal } from '~/components/modal';
import { Select } from '~/components/select';
import { PROVIDERS_FIELDS, PROVIDER_OPTIONS, TOKEN_PROVIDERS } from '~/config/constants/providers';
import { useCreateToken } from '~/hooks/use-create-token';
import { TokenPayloadFormatter } from '~/utils/token-payload';

type FormState = {
  name: string;
  provider: string;
  credentials: Record<string, string>;
};

type Props = {
  visible: boolean;
  onClose: () => void;
};

export function CreateTokenModal(props: Props) {
  const { visible, onClose } = props;
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useCreateToken();

  const {
    handleSubmit: handleFormSubmit,
    control,
    formState,
    reset,
    watch,
  } = useForm<FormState>({
    resolver: yupResolver(createValidation()),
    defaultValues: {
      name: '',
      provider: '',
      credentials: {},
    },
    mode: 'all',
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleSubmit = async (formValues: FormState) => {
    try {
      const result = await mutateAsync(TokenPayloadFormatter.format(formValues));

      if (result.success) {
        handleClose();
      }
    } catch {}
  };

  const selectedProvider = watch('provider');
  const fields = selectedProvider ? PROVIDERS_FIELDS[selectedProvider] || [] : [];

  return (
    <Modal visible={visible} onClose={isPending ? () => {} : handleClose}>
      <form noValidate onSubmit={handleFormSubmit(handleSubmit)}>
        <div className="flex flex-col gap-8 px-4 pt-2">
          <div className="flex flex-col gap-4">
            <h2 className="text-lg font-bold">{t('PAGES.TOKENS.CREATE')}</h2>

            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  label={t('TITLES.NAME')}
                  name={field.name}
                  id={field.name}
                  value={field.value}
                  error={formState.errors?.[field.name]?.message}
                  onChange={(e) => field.onChange(e.target.value)}
                  required
                />
              )}
            />

            <Controller
              name="provider"
              control={control}
              render={({ field }) => (
                <Select
                  label={t('TITLES.PROVIDER')}
                  name={field.name}
                  id={field.name}
                  defaultValue=""
                  value={field.value}
                  options={PROVIDER_OPTIONS}
                  error={formState.errors?.[field.name]?.message}
                  onChange={(e) => field.onChange(e.target.value)}
                  required
                />
              )}
            />

            <FieldSelector<FormState> control={control} fields={fields} />
          </div>

          <div className="flex justify-end gap-4 w-1/2 self-end">
            <Button
              type="button"
              variant="outlined"
              fullWidth
              className="font-semibold"
              disabled={isPending}
              onClick={handleClose}
            >
              {t('TITLES.CANCEL')}
            </Button>

            <Button
              type="submit"
              fullWidth
              loading={isPending}
              disabled={!formState.isValid || isPending}
            >
              {t('TITLES.SAVE')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

const getCredentialsValidation = (provider: string) => {
  if (!provider) return yup.object().shape({});
  if (!PROVIDERS_FIELDS[provider]) return yup.object().shape({});

  return yup.object().shape(
    PROVIDERS_FIELDS[provider].reduce(
      (acc, field) => ({
        ...acc,
        [field.name.split('.').pop() || '']: field.required
          ? yup.string().trim().required(t('ERRORS.REQUIRED_FIELD'))
          : yup.string().trim().optional(),
      }),
      {}
    )
  );
};

const createValidation = () =>
  yup.object().shape({
    name: yup.string().trim().required(t('ERRORS.REQUIRED_FIELD')),
    provider: yup
      .string()
      .trim()
      .required(t('ERRORS.REQUIRED_FIELD'))
      .oneOf(Object.values(TOKEN_PROVIDERS), t('ERRORS.INVALID_PROVIDER')),
    credentials: yup
      .object()
      .shape({})
      .when('provider', {
        is: TOKEN_PROVIDERS.SAMSUNG,
        then: () => getCredentialsValidation(TOKEN_PROVIDERS.SAMSUNG),
      }),
  });
