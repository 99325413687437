import { useQuery } from '@tanstack/react-query';

import { UserService } from '~/services/user';

const usersFetcher = () => new UserService().list().then((result) => result.data?.data);

export function useUsers() {
  return useQuery({
    queryKey: ['users'],
    queryFn: usersFetcher,
  });
}
