import { t } from 'i18next';
import { FieldPath, FieldValues } from 'react-hook-form';

export const TOKEN_PROVIDERS = {
  SAMSUNG: 'samsung',
};

export const PROVIDER_OPTIONS = Object.values(TOKEN_PROVIDERS).map((provider) => ({
  value: provider,
  label: t(`PROVIDERS.${provider.toUpperCase()}`, { defaultValue: provider }),
}));

export type ProviderField<F extends FieldValues = Record<string, unknown>> =
  | {
      type: 'text' | 'password';
      name: FieldPath<F>;
      placeholder?: string;
      required?: boolean;
      label: string;
    }
  | {
      type: 'select';
      name: FieldPath<F>;
      label: string;
      required?: boolean;
      options: { label: string; value: string }[];
    };

export type SamsungFields = {
  'credentials.api_login': string;
  'credentials.api_password': string;
};

export const PROVIDERS_FIELDS: Record<string, ProviderField[]> = {
  [TOKEN_PROVIDERS.SAMSUNG]: [
    {
      type: 'text',
      name: 'credentials.api_login',
      label: t('TITLES.INTEGRATION_USERNAME'),
      required: true,
    },
    {
      type: 'password',
      name: 'credentials.api_password',
      label: t('TITLES.INTEGRATION_PASSWORD'),
      required: true,
    },
  ] as ProviderField<SamsungFields>[],
};
