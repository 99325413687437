import { useTranslation } from 'react-i18next';

import { LoadingSpinner } from '~/components/loading';
import { Modal } from '~/components/modal';
import { SwitchToggle } from '~/components/switch-toggle';
import { useUpdateToken } from '~/hooks/use-update-token';

import { useToken } from '../../../hooks/use-token';

type Props = {
  tokenId: string | null;
  visible: boolean;
  onClose: () => void;
};

export function ShowTokenModal(props: Props) {
  const { tokenId, visible, onClose } = props;
  const { t } = useTranslation();
  const { data: token, isLoading } = useToken(tokenId);

  const { mutate: updateToken, isPending: isUpdating } = useUpdateToken(tokenId || '');

  const handleActiveChange = (checked: boolean) => {
    updateToken({ is_active: checked });
  };

  if (!tokenId || !visible) return null;

  return (
    <Modal visible={visible && !!tokenId} disabledClose={isUpdating} onClose={onClose}>
      <div className="flex flex-col gap-8 p-4">
        {isLoading && <LoadingSpinner />}

        {!isLoading && token && (
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2">
              <p className="text-xs font-bold">{t('TITLES.ACTIVE')}</p>

              {isUpdating && <LoadingSpinner />}
              {!isUpdating && (
                <SwitchToggle checked={token.is_active} onChange={handleActiveChange} />
              )}
            </div>

            <div className="flex flex-col gap-0.5">
              <p className="text-xs font-bold">{t('TITLES.NAME')}</p>
              <p>{token.name}</p>
            </div>

            <div className="flex flex-col gap-0.5">
              <p className="text-xs font-bold">{t('TITLES.PROVIDER')}</p>
              <p>{token.provider ? t(`PROVIDERS.${token.provider.toUpperCase()}`) : '-'}</p>
            </div>

            {token.integration?.credentials && (
              <div className="flex flex-col gap-0.5">
                <p className="text-xs font-bold">{t('TITLES.TOKEN_CREDENTIALS')}</p>

                <div className="flex flex-col gap-1">
                  {Object.entries(token.integration.credentials).map(([field, value]) => (
                    <p key={field} className="text-sm break-all">
                      <span className="font-bold">
                        {field}
                        {': '}
                      </span>
                      {value}
                    </p>
                  ))}
                </div>
              </div>
            )}

            {!!token.token && (
              <div className="flex flex-col gap-0.5">
                <p className="text-xs font-bold">{t('TITLES.TOKEN_KEY')}</p>

                <div className="flex flex-col gap-1">
                  <p className="text-sm break-all">{token.token}</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
