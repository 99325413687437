import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { TokenService } from '~/services/token';
import { UpdateTokenPayload } from '~/services/types/token';

import { useToast } from './use-toast';

const handleMutate = (tokenId: string) => (payload: UpdateTokenPayload) =>
  new TokenService().update(tokenId, payload);

type Options = {
  onSuccess?: () => void;
};

export function useUpdateToken(tokenId: string, options?: Options) {
  const { notify } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: handleMutate(tokenId),
    onSuccess: () => {
      notify('success', t('MESSAGES.TOKEN_UPDATED_SUCCESS'));
      queryClient.invalidateQueries({ queryKey: ['tokens'], exact: false });
      options?.onSuccess?.();
    },
    onError: () => {
      notify('error', t('ERRORS.WAS_NOT_POSSIBLE_UPDATE_TOKEN'));
    },
  });
}
