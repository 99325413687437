import clsx from 'clsx';
import { SVGProps } from 'react';

type Props = Omit<SVGProps<SVGSVGElement>, 'ref'> & {
  position: 'left' | 'right' | 'down' | 'up';
};

const POSITIONS = {
  left: '-rotate-90',
  right: 'rotate-90',
  down: 'rotate-180',
  up: 'rotate-0',
};

export function ChevronIcon(props: Props) {
  return (
    <svg
      {...props}
      width="7"
      height="7"
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(
        'transition ease-in-out delay-150',
        'text-gray-700',
        props.className || '',
        POSITIONS[props.position] ?? POSITIONS.right
      )}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.49987 1.99988C3.62787 1.99988 3.75587 2.04888 3.85337 2.14638L5.85337 4.14638C6.04887 4.34188 6.04887 4.65787 5.85337 4.85338C5.65788 5.04888 5.34188 5.04888 5.14637 4.85338L3.49387 3.20088L1.84737 4.79088C1.64787 4.98238 1.33238 4.97688 1.14038 4.77838C0.948375 4.57988 0.953875 4.26288 1.15238 4.07138L3.15237 2.14038C3.24987 2.04638 3.37487 1.99988 3.49987 1.99988Z"
        fill="currentColor"
      />
    </svg>
  );
}
