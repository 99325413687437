import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { appRoutes } from '~/config/routes';
import { useAppContext } from '~/hooks/use-app-context';
import { useToast } from '~/hooks/use-toast';
import { AuthService } from '~/services/auth';

type Props = {
  children: React.ReactNode;
};

const validateToken = () => new AuthService().getMe();

export function LoginValidationProvider(props: Props) {
  const { token } = useAppContext();
  const { notify } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isTokenValid, setIsTokenValid] = useState(true);

  useEffect(() => {
    if (!token) {
      navigate(appRoutes.login);
    }
  }, [token, navigate]);

  useEffect(() => {
    if (!token) return;

    async function validate() {
      try {
        const result = await validateToken();

        if (!!result.data.success) {
          setIsTokenValid(true);
          return;
        }

        navigate(appRoutes.login);
        notify('error', t('ERRORS.UNAUTHORIZED'));
      } catch {
        navigate(appRoutes.login);
        notify('error', t('ERRORS.UNAUTHORIZED'));
      }
    }

    validate();
  }, [token]);

  if (!token || !isTokenValid) return null;

  return <>{props.children}</>;
}
