import { BrowserRouter } from 'react-router-dom';

import '~/assets/styles/global.css';
import { AppContextProvider } from '~/context/app';
import '~/providers/locale';
import { QueryProvider } from '~/providers/query';
import { ToastProvider } from '~/providers/toast';

import { Router } from '../../routes';

export function App() {
  return (
    <ToastProvider>
      <BrowserRouter>
        <QueryProvider>
          <AppContextProvider>
            <Router />
          </AppContextProvider>
        </QueryProvider>
      </BrowserRouter>
    </ToastProvider>
  );
}
