import clsx from 'clsx';
import { InputHTMLAttributes } from 'react';

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  error?: string;
  icon?: React.ReactNode;
  onIconClick?: () => void;
};

export function Input(props: Props) {
  const { id = 'input', label, error, icon, onIconClick, ...inputProps } = props;

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <label className="text-gray-700 text-sm font-bold" htmlFor={id}>
          {label}
          {inputProps.required && <span className="text-red-500">&nbsp;{'*'}</span>}
        </label>
      )}

      <div className="relative w-full h-full">
        <input
          {...inputProps}
          value={inputProps.value || ''}
          className={clsx(
            'transition ease-in-out delay-150',
            'appearance-none',
            'border rounded',
            'py-2 px-3',
            'text-gray-700',
            'w-full',
            'shadow  leading-tight',
            'focus:outline-none focus:shadow-outline focus:border-primary-400',
            !!error && 'border border-red-500',
            props.className || ''
          )}
        />

        {!!icon && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
            onClick={onIconClick}
          >
            {icon}
          </button>
        )}
      </div>

      {!!error && <p className="text-red-500 text-xs bold">{error}</p>}
    </div>
  );
}
