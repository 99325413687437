import { createContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { CacheKeys } from '~/config/cache';
import { useLocalStorageState } from '~/hooks/use-local-storage-state';

type AppContextData = {
  token: string | null;

  signOut: () => void;
  updateToken: (token: string) => void;
  resetToken: () => void;
};

export const initialContextValue: AppContextData = {
  token: null,

  signOut: () => {},
  updateToken: () => {},
  resetToken: () => {},
};

export const AppContext = createContext<AppContextData>(initialContextValue as AppContextData);

type Props = {
  children: React.ReactNode;
};

export function AppContextProvider({ children }: Props) {
  const [token, setToken] = useLocalStorageState<string | null>(CacheKeys.authToken, null);
  const navigate = useNavigate();

  const updateToken = (newToken: string | null) => {
    if (newToken) {
      setToken(newToken);
    }
  };

  const resetToken = () => setToken(null);

  const signOut = () => {
    resetToken();
    navigate('/');
  };

  return (
    <AppContext.Provider
      value={{
        token,
        updateToken,
        resetToken,
        signOut,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
