import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { Input } from '~/components/input';
import { Select } from '~/components/select';
import { ProviderField } from '~/config/constants/providers';

type Props<F extends FieldValues> = {
  control: Control<F>;
  fields: ProviderField[];
};

export function FieldSelector<F extends FieldValues>(props: Props<F>) {
  return (
    <>
      {props.fields.map((fieldToSelect) => {
        switch (fieldToSelect.type) {
          case 'text':
            return (
              <Controller
                key={fieldToSelect.name}
                name={fieldToSelect.name as FieldPath<F>}
                control={props.control}
                render={({ field: { name, value, onChange }, formState }) => (
                  <Input
                    type={fieldToSelect.type}
                    label={fieldToSelect.label}
                    name={name}
                    id={name}
                    value={value}
                    placeholder={fieldToSelect.placeholder}
                    error={
                      !!formState.errors?.[name]?.message
                        ? String(formState.errors[name]?.message)
                        : undefined
                    }
                    autoComplete="off"
                    onChange={(e) => onChange(e.target.value)}
                    required={fieldToSelect.required}
                  />
                )}
              />
            );

          case 'password':
            return (
              <Controller
                key={fieldToSelect.name}
                name={fieldToSelect.name as FieldPath<F>}
                control={props.control}
                render={({ field: { name, value, onChange }, formState }) => (
                  <Input
                    type={fieldToSelect.type}
                    label={fieldToSelect.label}
                    name={name}
                    id={name}
                    value={value}
                    placeholder={fieldToSelect.placeholder}
                    error={
                      !!formState.errors?.[name]?.message
                        ? String(formState.errors[name]?.message)
                        : undefined
                    }
                    autoComplete="off"
                    onChange={(e) => onChange(e.target.value)}
                    required={fieldToSelect.required}
                  />
                )}
              />
            );

          case 'select':
            return (
              <Controller
                key={fieldToSelect.name}
                name={fieldToSelect.name as FieldPath<F>}
                control={props.control}
                render={({ field: { name, value, onChange }, formState }) => (
                  <Select
                    label={fieldToSelect.label}
                    name={name}
                    id={name}
                    value={value}
                    defaultValue={''}
                    error={
                      !!formState.errors?.[name]?.message
                        ? String(formState.errors[name]?.message)
                        : undefined
                    }
                    onChange={(e) => onChange(e.target.value)}
                    required={fieldToSelect.required}
                    options={fieldToSelect.options}
                  />
                )}
              />
            );

          default:
            return null;
        }
      })}
    </>
  );
}
