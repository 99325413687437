import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useAppContext } from '~/hooks/use-app-context';

import { appRoutes } from '../../config/routes';
import { LoginValidationProvider } from '../../providers/login-validation';
import { KeyIcon } from '../icons/key';
import { SignOutIcon } from '../icons/sign-out';
import { UsersIcon } from '../icons/users';

const MENUS = [
  {
    name: 'PAGES.MENU.TOKENS',
    icon: KeyIcon,
    path: appRoutes.base,
  },
  {
    name: 'PAGES.MENU.USERS',
    icon: UsersIcon,
    path: appRoutes.users,
  },
];

type Props = {
  children: React.ReactNode;
};

export function PageLayout(props: Props) {
  const { children } = props;
  const { signOut } = useAppContext();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isMenuActive = (path: string) => {
    if (path === '/') return path === pathname;
    return pathname.startsWith(path);
  };

  return (
    <LoginValidationProvider>
      <div className="flex w-full h-full bg-gray-50 overflow-x-hidden">
        <aside
          className="w-64 h-screen pt-10 bg-white border-r border-gray-200 md:translate-x-0"
          aria-label="Sidenav"
          id="drawer-navigation"
        >
          <div className="flex flex-col gap-8 justify-between overflow-y-auto py-5 px-3 h-full bg-white">
            <ul className="space-y-2">
              {MENUS.map((menu) => (
                <li key={menu.name}>
                  <Link
                    to={menu.path}
                    className={clsx(
                      isMenuActive(menu.path) &&
                        'font-bold bg-primary-500 text-primary-50 border border-primary-500 ',
                      !isMenuActive(menu.path) && 'hover:bg-gray-100',
                      'flex items-center px-4 py-2 text-base font-medium text-gray-900 rounded-lg group'
                    )}
                  >
                    {<menu.icon variant={isMenuActive(menu.path) ? 'filled' : 'outlined'} />}
                    <span className="ml-3">{t(menu.name)}</span>
                  </Link>
                </li>
              ))}
            </ul>

            <button
              type="button"
              onClick={signOut}
              className="transition ease-in-out delay-50 hover:text-red-500 flex gap-2 align-center justify-center"
            >
              <SignOutIcon className="p-0.5" />
              {t('TITLES.LOGOUT')}
            </button>
          </div>
        </aside>

        <main className="flex-1 p-4 pt-10 h-full w-full max-w-full">{children}</main>
      </div>
    </LoginValidationProvider>
  );
}
