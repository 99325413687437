import { api } from '~/config/api';

import { HttpService } from './http';
import {
  CreateTokenPayload,
  CreateTokenResult,
  GetTokenResult,
  ListTokensResult,
  UpdateTokenPayload,
  UpdateTokenResult,
} from './types/token';

export class TokenService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  list() {
    return this.httpService.get<ListTokensResult>(api.tokens.list);
  }

  getDetails(tokenId: string) {
    return this.httpService.get<GetTokenResult>(api.tokens.details.replace(':id', tokenId));
  }

  create(payload: CreateTokenPayload) {
    return this.httpService.post<CreateTokenResult>(api.tokens.base, payload);
  }

  update(id: string, payload: UpdateTokenPayload) {
    return this.httpService.put<UpdateTokenResult>(api.tokens.update.replace(':id', id), payload);
  }

  delete(id: string) {
    return this.httpService.delete(api.tokens.delete.replace(':id', id));
  }
}
